export const BaseUrl = (baseUrl) => {
  let curPage = window.location.hostname;
  if (curPage.includes("dev") || curPage.includes("localhost")) {
    baseUrl =
      "https://zeblobstorage.blob.core.windows.net/zeblob/ProjectDb/Tvs/NewAchri/";
  } else if (curPage.includes("staging")) {
    // baseUrl = "https://assets.zdviewer.com/zdviewer/NewAchri/";
    // baseUrl = "https://assets.zdviewer.com/zdviewer/NewAchriStaging/";

    baseUrl =
      "https://zeblobstorage.blob.core.windows.net/zeblob/ProjectDb/ZDViewer/NewAchriStaging/";
    //"https://zdviewerassets.blob.core.windows.net/zdviewer/NewAchriStaging/";
  } else {
    baseUrl = "https://assets.zdviewer.com/zdviewer/NewAchri/";
  }
  return baseUrl;
};

export const VehicleCode = 1161;
export const VehicleName = "RTR-310";

export const ConfigBaseUrl = (baseUrl) => {
  let curPage = window.location.hostname;
  if (
    curPage.includes("dev") ||
    curPage.includes("localhost") ||
    curPage.includes("staging")
  ) {
    // baseUrl = "https://zdvservice.azurewebsites.net/api/arive/";
    baseUrl = "https://zdvservice.azurewebsites.net/api/preprod/arive/";
  } else {
    baseUrl = "https://zdvservice.azurewebsites.net/api/arive/";
  }
  return baseUrl;
};
